import React from "react";

function SeventhSection() {
  return (
    <div className="seventh">
      <biv className="seventh-bg">
        <h1 className="style-heading">Get in Touch</h1>
      </biv>
      <div></div>
    </div>
  );
}

export default SeventhSection;
